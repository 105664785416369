// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-articles-facebook-en-js": () => import("./../src/pages/articles/facebook.en.js" /* webpackChunkName: "component---src-pages-articles-facebook-en-js" */),
  "component---src-pages-articles-facebook-js": () => import("./../src/pages/articles/facebook.js" /* webpackChunkName: "component---src-pages-articles-facebook-js" */),
  "component---src-pages-articles-google-en-js": () => import("./../src/pages/articles/google.en.js" /* webpackChunkName: "component---src-pages-articles-google-en-js" */),
  "component---src-pages-articles-google-js": () => import("./../src/pages/articles/google.js" /* webpackChunkName: "component---src-pages-articles-google-js" */),
  "component---src-pages-articles-mail-en-js": () => import("./../src/pages/articles/mail.en.js" /* webpackChunkName: "component---src-pages-articles-mail-en-js" */),
  "component---src-pages-articles-mail-js": () => import("./../src/pages/articles/mail.js" /* webpackChunkName: "component---src-pages-articles-mail-js" */),
  "component---src-pages-articles-qr-en-js": () => import("./../src/pages/articles/qr.en.js" /* webpackChunkName: "component---src-pages-articles-qr-en-js" */),
  "component---src-pages-articles-qr-js": () => import("./../src/pages/articles/qr.js" /* webpackChunkName: "component---src-pages-articles-qr-js" */),
  "component---src-pages-articles-website-en-js": () => import("./../src/pages/articles/website.en.js" /* webpackChunkName: "component---src-pages-articles-website-en-js" */),
  "component---src-pages-articles-website-js": () => import("./../src/pages/articles/website.js" /* webpackChunkName: "component---src-pages-articles-website-js" */),
  "component---src-pages-content-js": () => import("./../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

