module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Perspective Resources","short_name":"Resources","start_url":"/","icon":"static/apple-touch-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"enableOnDevMode":false,"apiToken":"1274d7b632c8e33cf10fc37cbc8350e8"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
